import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
// import * as ActiveStorage from "@rails/activestorage"
import $ from 'jquery';
import "channels"
import "controllers"

// If set `Turbo.session.drive` to false, all form submit becomes regular form submit, not turbo frame. 
// - ie `render turbo_frame.replace(...)` does not work. It will render this response as the whole page.
// 
// Turbo.session.drive = false;
Rails.start()
global.jQuery = $;
global.$ = $;

// Inject a header into turbolinks requests so the above
// nonce generation code works
document.addEventListener('turbolinks:request-start', function(event) {
  const xhr = event.data.xhr;
  // console.log(">>>>");
  // console.log( $('meta[name=\'csp-nonce\']').prop('content'));
  xhr.setRequestHeader('X-Turbolinks-Nonce', $('meta[name=\'csp-nonce\']').prop('content'));
});

// Because nonces can only be accessed via their IDL attribute
// after the page loads (for security reasons),
// they need to be read via JS and added back as
// normal attributes in the DOM before the page is cached
// otherwise on cache restoration visits, the nonces won’t be there!
document.addEventListener('turbolinks:before-cache', function() {
  $('script[nonce]').each(function(index, element) {
    $(element).attr('nonce', element.nonce);
  });
});

$('.dropdown-toggle').dropdown()
