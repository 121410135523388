import { Controller } from "stimulus"
import { Modal } from "bootstrap"
import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = ["size", "url", "localContent"]

    connect() {
      console.log("stimulus modal load")
      this.mainModal = document.getElementById('modal')
      this.modal = new Modal(this.mainModal)
			window.mainModal = this.modal
			$(this.element).on('hide.bs.modal', (event) => {
				this.closeCallback()
			})
    }

    async toggle(){
        this.mainModal.querySelector('.modal-content').innerHTML = ""
        this.mainModal.querySelector('.modal-dialog').classList
                      .remove("animated", "shake", "modal-lg", "modal-xl", "modal-sm")

        await this.load(this.data.get("url"))
        this.modal.show()
    }

    async toggleLocal(){
        this.mainModal.querySelector('.modal-content').innerHTML = ""
        this.mainModal.querySelector('.modal-dialog').classList
                      .remove("animated", "shake", "modal-lg", "modal-xl", "modal-sm")
        const content = document.getElementById(this.data.get("localContent"))
        this.mainModal.querySelector('.modal-content').innerHTML = content.innerHTML
        this.modal.show()
    }

    load(path) {
      Rails.ajax({
          type: 'GET',
          url: path,
          dataType: 'script', // remote -> true
          success: (response) => this._success(response),
          complete: (response) => this._complete(),
          error: (response) => this._errors()
      });
        // fetch(path)
        // .then((r) => r.text())
        // .then((html) => {
        //     this.mainModal.querySelector('.modal-content').innerHTML = html
        // });
    }

    _success(response) {
        this.mainModal.querySelector('.modal-dialog').classList
                      .add(`modal-${this.data.get('size')}`)
                      // this.modal.hide()

        // let [data, status, xhr] = response.detail;
        // console.log(xhr.response)
        // this.mainModal.querySelector('.modal-content').innerHTML = response
    }

    _complete() {
    }

    _error() {
    }

		closeCallback() {
			console.log('close modal')
			console.log(this.data.get('callback'))
    }

    onPostSuccess(){
      // reload
      // this.mainModal.querySelector('.modal-content').innerHTML = ""
      // this.mainModal.querySelector('.modal-dialog').classList
      //               .remove("animated", "shake", "modal-lg", "modal-xl", "modal-sm")
      // this.modal.hide()
      // $('.modal-backdrop').remove()
    }

    onPostError(){
    }

}
