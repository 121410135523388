import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "filesInput",
    "fileItem",
    "filesContainer",
    "uploadPath",
    "message",
    "submitButton"
  ];

  initialize() {
    this.uploading = false;
  }
  
  uploadFile(event) {
    if (this.uploading) {
      alert('Please wait for upload to finish.');
      return;
    }

    this.messageTarget.textContent = "";
    const filesInput = this.filesInputTarget;
    let files = Array.from(filesInput.files);
    
    if (files.length === 0) {
      return;
    }
    
    if ((files.length + this.filesContainerTarget.children.length) > 10) {
      alert('Please do not upload more than 10 files.')
      return;
    }

    // should change state to prevent further upload until current one completes.   
    this.uploading = true;
    this.messageTarget.textContent = "Uploading....";
    this.submitButtonTarget.disabled = true;

    let formData = new FormData();

    files.forEach((file) => {
      formData.append("attachments[]", file);
    });

    // Reset after 1 minute
    setTimeout(() => {
      this.messageTarget.textContent = "";
      this.submitButtonTarget.disabled = false;
      this.uploading = false;
    }, 50000);

    const uploadPath = this.element.dataset.uploadPath;
    fetch(uploadPath, {
      method: "POST",
      body: formData,
      headers: { 
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")
      },
    })
      .then((response) => {
        this.messageTarget.textContent = "";
        this.submitButtonTarget.disabled = false;

        if (response.ok) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (data?.result === "success") {
          var range = document.createRange();
          this.filesContainerTarget.appendChild(range.createContextualFragment(data.html));
          filesInput.value = "";
          this.uploading = false;
        } else {
          alert(data);
        }
      });
  }

  removeFile(event) {
    event.preventDefault();
    const signedId = event.target.dataset.signedId;
    const uploadPath = this.element.dataset.uploadPath;
    
    fetch(`${uploadPath}/${signedId}`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        },
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (data?.result === "success") {
          this.filesContainerTarget.querySelector(`[data-row-id='${signedId}'`)?.remove();
        } else {
          alert(data);
        }
      });
    }
}